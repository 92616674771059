import axios from "axios";
import proxy from "../../commons/proxy";
import chartModel from "../../models/chartModel.js";

export default {
  namespaced: true,

  // Initial state
  state: {
    primaryDataChartValues: [],
    challengeDurationByWeekDaysValues: null,
    challengeDurationByMonthsValues: null,
    challengeEffortByWeekDaysValues: null,
    challengeEffortByMonthsValues: null,
    challengeDistanceByWeekDaysValues: null,
    challengeSpeedByWeekDaysValues: null,
    challengeDistanceByMonthsValues: null,
    challengeSpeedByMonthsValues: null,
  },

  // Getters
  getters: {},

  // Mutations
  mutations: {
    SET_PrimaryDataChartValues(state, items) {
      if (items) {
        let objectsCollection = [];
        for (let index = 0; index < items.length; ++index) {
          let jsonObject = items[index];
          let dataObject = new chartModel(jsonObject);
          objectsCollection.push(dataObject);
        }
        state.primaryDataChartValues = objectsCollection;
      } else {
        console.log(
          "Failed to deserialize challenge Primary Data Chart Values for collection!"
        );
      }
    },
    SET_ChallengeDurationByWeekDays(state, item) {
      if (item) {
        let dataObject = new chartModel(item);
        state.challengeDurationByWeekDaysValues = dataObject;
      }
    },
    SET_ChallengeDurationByMonths(state, item) {
      if (item) {
        // let objectsCollection = [];
        // for (let index = 0; index < items.length; ++index) {
        //   let jsonObject = items[index];
        //   let dataObject = new chartModel(jsonObject);
        //   objectsCollection.push(dataObject);
        // }
        let dataObject = new chartModel(item);
        state.challengeDurationByMonthsValues = dataObject;
      }
    },
    SET_ChallengeEffortByWeekDays(state, item) {
      if (item) {
        let dataObject = new chartModel(item);
        state.challengeEffortByWeekDaysValues = dataObject;
      }
    },
    SET_ChallengeEffortByMonths(state, item) {
      if (item) {
        let dataObject = new chartModel(item);
        state.challengeEffortByMonthsValues = dataObject;
      }
    },
    SET_ChallengeDistanceByWeekDays(state, item) {
      if (item) {
        let dataObject = new chartModel(item);
        state.challengeDistanceByWeekDaysValues = dataObject;
      }
    },
    SET_ChallengeSpeedByWeekDays(state, item) {
      if (item) {
        let dataObject = new chartModel(item);
        state.challengeSpeedByWeekDaysValues = dataObject;
      }
    },
    SET_ChallengeDistanceByMonths(state, item) {
      if (item) {
        let dataObject = new chartModel(item);
        state.challengeDistanceByMonthsValues = dataObject;
      }
    },
    SET_ChallengeSpeedByMonths(state, item) {
      if (item) {
        let dataObject = new chartModel(item);
        state.challengeSpeedByMonthsValues = dataObject;
      }
    },
  },

  // Action
  actions: {
    getPrimaryDataChartValues(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_PrimaryDataChartValues", []);
      axios
        .get(
          //   `${proxy.baseUrl}/chart/getChallengePrimaryValues?challengeId=${challengeId}&start=${start}&finish=${finish}`,
          `${proxy.baseUrl}/chart/getChallengePrimaryValues?challengeId=${challengeId}`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_PrimaryDataChartValues", response.data);
        });
    },
    getChallengeDurationByWeekDays(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_ChallengeDurationByWeekDays", null);
      axios
        .get(
          //   `${proxy.baseUrl}/chart/getChallengeSeriesByWeekDays?challengeId=${challengeId}&start=${start}&finish=${finish}`,
          `${proxy.baseUrl}/chart/getChallengeSeriesByWeekDays?challengeId=${challengeId}&workoutDataType=ActiveTimeTotal`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_ChallengeDurationByWeekDays", response.data);
        });
    },
    getChallengeDurationByMonths(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_ChallengeDurationByMonths", null);
      axios
        .get(
          //   `${proxy.baseUrl}/chart/getChallengeSeriesByMonths?challengeId=${challengeId}&start=${start}&finish=${finish}`,
          `${proxy.baseUrl}/chart/getChallengeSeriesByMonths?challengeId=${challengeId}&workoutDataType=ActiveTimeTotal`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_ChallengeDurationByMonths", response.data);
        });
    },
    getChallengeEffortByWeekDays(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_ChallengeEffortByWeekDays", null);
      axios
        .get(
          //   `${proxy.baseUrl}/chart/getChallengeSeriesByWeekDays?challengeId=${challengeId}&start=${start}&finish=${finish}`,
          `${proxy.baseUrl}/chart/getChallengeSeriesByWeekDays?challengeId=${challengeId}&workoutDataType=GetEffort`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_ChallengeEffortByWeekDays", response.data);
        });
    },
    getChallengeEffortByMonths(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_ChallengeEffortByMonths", null);
      axios
        .get(
          //   `${proxy.baseUrl}/chart/getChallengeSeriesByMonths?challengeId=${challengeId}&start=${start}&finish=${finish}`,
          `${proxy.baseUrl}/chart/getChallengeSeriesByMonths?challengeId=${challengeId}&workoutDataType=GetEffort`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_ChallengeEffortByMonths", response.data);
        });
    },
    getChallengeDistanceByWeekDays(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_ChallengeDistanceByWeekDays", null);
      axios
        .get(
          //   `${proxy.baseUrl}/chart/getChallengeSeriesByWeekDays?challengeId=${challengeId}&start=${start}&finish=${finish}`,
          `${proxy.baseUrl}/chart/getChallengeSeriesByWeekDays?challengeId=${challengeId}&workoutDataType=DistanceTotal`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_ChallengeDistanceByWeekDays", response.data);
        });
    },
    getChallengeSpeedByWeekDays(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_ChallengeSpeedByWeekDays", null);
      axios
        .get(
          `${proxy.baseUrl}/chart/getChallengeSeriesByWeekDays?challengeId=${challengeId}&workoutDataType=SpeedAverage`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_ChallengeSpeedByWeekDays", response.data);
        });
    },
    getChallengeDistanceByMonths(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_ChallengeDistanceByMonths", null);
      axios
        .get(
          //   `${proxy.baseUrl}/chart/getChallengeSeriesByMonths?challengeId=${challengeId}&start=${start}&finish=${finish}`,
          `${proxy.baseUrl}/chart/getChallengeSeriesByMonths?challengeId=${challengeId}&workoutDataType=DistanceTotal`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_ChallengeDistanceByMonths", response.data);
        });
    },
    getChallengeSpeedByMonths(
      { commit, state },
      { challengeId, start, finish }
    ) {
      commit("SET_ChallengeSpeedByMonths", null);
      axios
        .get(
          //   `${proxy.baseUrl}/chart/getChallengeSeriesByMonths?challengeId=${challengeId}&start=${start}&finish=${finish}`,
          `${proxy.baseUrl}/chart/getChallengeSeriesByMonths?challengeId=${challengeId}&workoutDataType=SpeedAverage`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_ChallengeSpeedByMonths", response.data);
        });
    },
  },
};
