<template>
  <v-container v-if="group">
    <v-card class="mx-auto my-1" max-width="1200">
      <v-toolbar color="#04bfd6 " dark>
        <v-list-item-avatar>
          <img :src="group.image" />
        </v-list-item-avatar>
        <v-toolbar-title>
          <h2>{{ group.name }}</h2>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item" @click="tabClicked(item)">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item key="challenges">
          <v-card flat v-if="getActiveChallenges">
            <v-list two-line subheader>
              <v-divider></v-divider>
              <v-subheader inset>Current</v-subheader>
              <thumbnail-challenge-component
                v-for="item in getActiveChallenges"
                :key="item.challengeId"
                :challenge="item"
              />
              <!-- Loop over each year in our computed 'yearsList' -->
              <!-- Loop over each year in our computed 'yearsList' -->
              <div v-for="year in yearsList" :key="year">
                <v-divider inset></v-divider>
                <v-subheader inset>{{ year }}</v-subheader>
                <thumbnail-challenge-component
                  v-for="item in getByYearChallenges(year)"
                  :key="item.challengeId"
                  :challenge="item"
                />
              </div>
              <!-- <v-divider inset></v-divider>
              <v-subheader inset>Expired</v-subheader>
              <thumbnail-challenge-component
                v-for="item in getExpiredChallenges"
                :key="item.challengeId"
                :challenge="item"
              /> -->
            </v-list>
          </v-card>
        </v-tab-item>
        <v-tab-item key="activities">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            v-if="!getWorkouts"
          ></v-progress-circular>
          <v-card flat v-if="getWorkouts">
            <v-list two-line subheader>
              <thumbnail-workout-component
                v-for="item in getWorkouts"
                :key="item.workoutId"
                :workout="item"
              />
            </v-list>
            <v-pagination
              v-model="page"
              :length="paginationLength"
              @input="onPageChange"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
            ></v-pagination>
          </v-card>
        </v-tab-item>
        <v-tab-item key="members">
          <v-card flat>
            <v-list two-line subheader>
              <thumbnail-user-component
                v-for="item in members"
                :key="item.groupUserAssignId"
                :user="item"
              />
            </v-list>
          </v-card>
        </v-tab-item>
        <v-tab-item key="about">
          <v-card flat>
            <v-card-text>{{ group.description }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ThumbnailWorkoutComponent from "../components/ThumbnailWorkoutComponent";
import ThumbnailUserComponent from "../components/ThumbnailUserComponent";
import ThumbnailChallengeComponent from "../components/ThumbnailChallengeComponent";

export default {
  name: "GroupDetailView",
  props: {
    groupId: String,
  },
  data() {
    return {
      tab: null,
      page: 1,
      items: ["challenges", "activities", "members", "about"],
    };
  },
  mounted() {
    this.getGroup(this.groupId);
    this.getGroupChallenges(this.groupId);
    this.getCountByGroup(this.groupId);
  },
  computed: {
    ...mapState("groups", {
      group: (state) => state.group,
      members: (state) => state.members,
    }),
    ...mapState("workouts", {
      groupWorkoutsCount: (state) => state.groupWorkoutsCount,
    }),
    ...mapGetters("groups", [
      "getActiveChallenges",
      "getExpiredChallenges",
      "getByYearChallenges",
    ]),
    ...mapGetters("workouts", ["getWorkouts"]),
    paginationLength: function () {
      return parseInt(this.groupWorkoutsCount / 50 + 1);
    },
    yearsList() {
      const startYear = 2022;
      const endYear = new Date().getFullYear() - 1;
      const years = [];

      // Loop backward from endYear to startYear
      for (let y = endYear; y >= startYear; y--) {
        years.push(y);
      }

      return years;
    },
  },
  methods: {
    ...mapActions("groups", [
      "getGroup",
      "getGroupMembers",
      "getGroupChallenges",
    ]),
    ...mapActions("workouts", ["getByGroup", "getCountByGroup"]),
    tabClicked(tabName) {
      if (tabName == "members") {
        this.getGroupMembers(this.groupId);
      } else if (tabName == "challenges") {
        this.getGroupChallenges(this.groupId);
      } else if (tabName == "activities") {
        this.getByGroup({ primaryKey: this.groupId, skip: 0 });
      } else if (tabName == "about") {
        this.getGroup(this.groupId);
      } else {
        console.log(
          `Failed to load tab data as tab (${tabName}) is not known!`
        );
      }
    },
    onPageChange(page) {
      this.getByGroup({
        primaryKey: this.groupId,
        skip: page * 50 - 50,
      });
      scroll(0, 0);
    },
  },
  components: {
    ThumbnailWorkoutComponent,
    ThumbnailUserComponent,
    ThumbnailChallengeComponent,
  },
};
</script>
