<template>
  <v-list-item v-if="participant" :key="participant.participant">
    <v-list-item-avatar>
      <v-icon v-if="participant.position == 1" medium color="yellow"
        >mdi-trophy</v-icon
      >
      <v-icon v-else-if="participant.position == 2" medium color="gray"
        >mdi-trophy</v-icon
      >
      <v-icon v-else-if="participant.position == 3" medium color="brown"
        >mdi-trophy</v-icon
      >
      <div v-else-if="participant.position == 0" :style="{ color: textColor }">
        <v-tooltip>
          <template #activator="{ onn }">
            <v-icon small color="red darken-2" v-on="onn"
              >mdi-account-arrow-right-outline</v-icon
            >
          </template>
          <span> User is of guest type and not applicable for prizes </span>
        </v-tooltip>
      </div>
      <h2 v-else style="color: black">
        {{ participant.position }}
      </h2>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-if="participant.position == 0" style="color: black">
        {{ participant.participant }}.</v-list-item-title
      >
      <v-list-item-title v-else>
        {{ participant.participant }}.</v-list-item-title
      >
      <v-row no-gutters class="mb-1">
        <v-col left cols="10">
          <h3
            :style="{ color: textColor }"
            class="font-weight-bold"
            v-if="challengeType == 'TotalDistance'"
          >
            {{ (participant.totalDistance.data / 1000).toFixed(2) }}
            km
          </h3>
          <h3
            :style="{ color: textColor }"
            class="font-weight-bold"
            v-if="challengeType == 'TotalEffort'"
          >
            {{ participant.totalEffort.data.toFixed(0) }} score
          </h3>
        </v-col>
      </v-row>
      <v-row no-gutters class="pl-1">
        <h6 :style="{ color: textColor }">
          <v-icon small color="blue darken-2">mdi-infinity</v-icon>&nbsp;{{
            participant.totalWorkouts.data
          }}
          workouts&nbsp;&nbsp;
        </h6>
        <h6 :style="{ color: textColor }">
          <v-icon small color="blue darken-2">mdi-timer-sand-full</v-icon
          >&nbsp;{{ (participant.totalActiveTime.data / 3600).toFixed(2) }}
          h&nbsp;&nbsp;
        </h6>
        <h6 :style="{ color: textColor }">
          <v-icon small color="blue darken-2">mdi-timer-sand-empty</v-icon
          >&nbsp;{{ (participant.totalPauseTime.data / 3600).toFixed(2) }}
          h&nbsp;&nbsp;
          <v-icon small color="orange darken-2">mdi-fire</v-icon
          >{{
            (participant.totalMetabolicEnergy.data * 0.239006 * 0.001).toFixed(
              0
            )
          }}
          kcal&nbsp;&nbsp;
        </h6>
        <v-icon small color="black darken-2">mdi-ab-testing</v-icon
        >&nbsp;&nbsp;&nbsp;
        <h6 v-if="participant.avrgHeartRate" :style="{ color: textColor }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon small color="red darken-2" v-on="on"
                >mdi-heart-pulse</v-icon
              >
            </template>
            <span>
              Heart rate:
              <strong
                >{{ participant.avrgHeartRate.data.toFixed(0) }}BPM</strong
              >
              <BR />HR zone:
              <strong
                >{{
                  this.heartRateZone(participant.avrgHeartRatePercentage.data)
                }}
                ({{
                  this.heartRateZonePercentage(
                    participant.avrgHeartRatePercentage.data
                  )
                }}%)</strong
              >
            </span>
          </v-tooltip>
          {{ participant.avrgHeartRatePercentage.data.toFixed(2) }}% (Z{{
            this.heartRateZone(participant.avrgHeartRatePercentage.data)
          }})&nbsp;&nbsp;
        </h6>
        <h6 :style="{ color: textColor }">
          <template
            v-if="
              challengeType == 'TotalDistance' &&
              participant.avrgDistance != null
            "
            ><v-icon small color="brown darken-2"
              >mdi-map-marker-distance</v-icon
            >&nbsp;{{
              (participant.avrgDistance.data / 1000).toFixed(2)
            }}km&nbsp;&nbsp;</template
          >
          <template
            v-if="
              challengeType == 'TotalDistance' && participant.avrgSpeed != null
            "
            ><v-icon small color="green darken-2">mdi-speedometer</v-icon
            >&nbsp;{{
              (participant.avrgSpeed.data * 3.6).toFixed(2)
            }}km/h&nbsp;&nbsp;</template
          >
          <template v-if="challengeType == 'TotalDistance'">
            <v-icon small color="lime darken-2">mdi-speedometer</v-icon>
            {{ this.pace(participant.avrgSpeed.data * 3.6) }}
            min/km&nbsp;&nbsp;
          </template>
        </h6>
        <h6 :style="{ color: textColor }">
          <v-icon small color="yellow darken-2">mdi-timer</v-icon>&nbsp;{{
            (participant.totalActiveTime.data > 0
              ? participant.totalActiveTime.data /
                participant.totalActiveTime.count /
                60
              : 0
            ).toFixed(2)
          }}min&nbsp;&nbsp;
        </h6>
        <h6 :style="{ color: textColor }">
          <v-icon small color="gray darken-2">mdi-sleep</v-icon>&nbsp;&nbsp;{{
            (participant.totalPauseTime.data > 0
              ? participant.totalPauseTime.data /
                participant.totalPauseTime.count /
                60
              : 0
            ).toFixed(2)
          }}min
        </h6>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import formulas from "../commons/formulas";

export default {
  name: "ChallengeParticipantPosition",
  props: {
    participant: Object,
    challengeType: String,
  },
  computed: {
    textColor: function () {
      if (this.participant.position == 0) return "#797979";
      else return "black";
    },
  },
  methods: {
    pace: function (speedKilometersPerHour) {
      if (this.challengeType != "TotalDistance") return "None";
      return formulas.paceFromKmh(speedKilometersPerHour);
    },
    heartRateZone: function (heartRatePercentage) {
      return formulas.getHeartRateZone(heartRatePercentage);
    },
    heartRateZonePercentage: function (heartRatePercentage) {
      return formulas.getHeartRateZonePercentage(heartRatePercentage);
    },
  },
  mounted() {},
  data: () => ({}),
};
</script>
