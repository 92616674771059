<template>
  <div>
    <v-list-item style="cursor: default" :key="workout.workoutId" link>
      <v-list-item-avatar>
        <!-- <v-icon x-large color="blue darken-2">mdi-account-circle</v-icon> -->
        <img x-large :src="workout.workoutTypeIcon" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-container>
          <v-row dense class="mb-1">
            <v-list-item-title class="headline font-weight-bold">
              <!-- <v-icon small color="blue darken-2">mdi-account-circle</v-icon> -->
              {{ workout.firstName }}
              {{ workout.lastName }}.&nbsp;
              <v-icon small :color="workout.hasGpsData ? 'green' : 'darken-2'"
                >mdi-crosshairs-gps</v-icon
              >
              <v-icon
                small
                :color="workout.hasGpsData ? 'green' : 'darken-2'"
                >{{
                  workout.hasGpsData ? "mdi-signal" : "mdi-signal-off"
                }}</v-icon
              >&nbsp;<v-icon
                small
                :color="workout.hasHeartRateData ? 'red' : 'darken-2'"
                >mdi-heart-pulse</v-icon
              ><v-icon
                small
                :color="workout.hasHeartRateData ? 'red' : 'darken-2'"
                >{{
                  workout.hasHeartRateData ? "mdi-signal" : "mdi-signal-off"
                }}</v-icon
              >
            </v-list-item-title>
          </v-row>
          <v-row dense class="mb-3">
            <v-list-item-subtitle
              ><h5>
                {{ this.workout.startDateTime.replace("T", " ") }}
              </h5>
            </v-list-item-subtitle>
          </v-row>
          <v-row dense class="mb-1">
            <v-list-item-subtitle class="font-weight-bold"
              ><h4
                style="color: black; cursor: pointer"
                class="font-weight-medium"
                link
                @click="openMapMyFitnessWorkout"
              >
                {{ workout.workoutName }}
              </h4>
            </v-list-item-subtitle>
          </v-row>
          <v-row dense class="mb-3">
            <v-list-item-subtitle
              ><h5>
                {{ workout.workoutType }}
              </h5>
            </v-list-item-subtitle>
          </v-row>
          <v-row dense class="mb-1">
            <h6>
              <v-icon large color="pink darken-2">mdi-timer-outline</v-icon>
              {{ workout.activeTimeTotal }}&nbsp;&nbsp;
              <v-icon small color="gray darken-2">mdi-sleep</v-icon
              >&nbsp;&nbsp;{{ workout.breakTimeTotal }}&nbsp;&nbsp;
            </h6>
          </v-row>
          <v-row dense class="mb-1">
            <h6 v-if="workout.effort > 0">
              <v-icon large color="blue darken-2">mdi-arm-flex-outline</v-icon
              >{{ workout.effort.toFixed(0) }} points&nbsp;&nbsp;
            </h6>
            <h6 v-if="workout.kcal > 0">
              <v-icon large color="orange darken-2">mdi-fire</v-icon
              >{{ workout.kcal.toFixed(0) }} kcal&nbsp;&nbsp;&nbsp;
            </h6>
            <!-- Heart rate -->
            <h6 v-if="workout.heartRateAverage" style="width: 83px">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon large color="red darken-2" v-on="on"
                    >mdi-heart-pulse</v-icon
                  >
                </template>
                <span>
                  Heart rate:
                  <strong>{{ workout.heartRatePercentage.toFixed(2) }}%</strong>
                  <BR />HR zone:
                  <strong
                    >{{ heartRateZone }} ({{
                      heartRateZonePercentage
                    }}%)</strong
                  >
                </span>
              </v-tooltip>
              {{ workout.heartRateAverage }} BPM
            </h6>
            <h6
              v-if="workout.heartRatePercentage"
              style="width: 45px; margin-left: -43.5px"
            >
              <small style="color: gray">
                {{ workout.heartRatePercentage.toFixed(2) }}%, Z{{
                  heartRateZone
                }}
              </small>
              &nbsp;&nbsp;
            </h6>

            <h6 v-if="workout.distanceTotal > 0">
              <v-icon large color="green darken-2">mdi-highway</v-icon
              >{{ (workout.distanceTotal / 1000).toFixed(3) }} km
              &nbsp;&nbsp;&nbsp;
            </h6>
            <!-- <h6 v-if="workout.totalAscent > 0">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon large color="blue-grey darken-1 darken-2" v-on="on"
                    >mdi-elevation-rise</v-icon
                  >
                </template>
                <span>
                  <strong>Elevation</strong><BR />Max:
                  <strong>{{ workout.maxElevation.toFixed(2) }}m</strong>
                  <BR />Min:
                  <strong>{{ workout.minElevation.toFixed(2) }} m</strong>
                </span>
              </v-tooltip>
              {{ workout.totalAscent }} m &nbsp;&nbsp;&nbsp;
            </h6> -->
            <!-- <h6 v-if="workout.totalDescent">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon large color="brown darken-1 darken-2" v-on="on"
                    >mdi-slope-downhill</v-icon
                  >
                </template>
                <span>
                  <strong>Elevation</strong><BR />Max:
                  <strong>{{ workout.maxElevation.toFixed(2) }}m</strong>
                  <BR />Min:
                  <strong>{{ workout.minElevation.toFixed(2) }} m</strong>
                </span>
              </v-tooltip>
              {{ workout.totalDescent }} m &nbsp;&nbsp;&nbsp;
            </h6> -->
            <h6 v-if="workout.speedAverage > 0">
              <v-icon large color="lime darken-2">mdi-speedometer</v-icon
              >{{ (workout.speedAverage * 3.6).toFixed(2) }}
              km/h &nbsp;&nbsp;
            </h6>
            <h6 v-if="workout.pace">
              <v-icon large color="lime darken-2">mdi-speedometer</v-icon
              >{{ workout.pace }}
              min/km&nbsp;&nbsp;
            </h6>
            <h6 v-if="workout.cadenceAverage > 0">
              <v-icon large color="wheat darken-2">{{
                workout.cadenceName == "RPM"
                  ? "mdi-autorenew"
                  : "mdi-shoe-print"
              }}</v-icon
              >{{ workout.cadenceAverage.toFixed(0) }}
              {{ workout.cadenceName }}&nbsp;&nbsp;
            </h6>
            <h6 v-if="workout.powerAverage > 0">
              <v-icon large color="yellow darken-2">mdi-flash-outline</v-icon
              >{{ workout.powerAverage }} W
            </h6>
            <h6 v-if="workout.wattsPerKilogram">
              <small style="color: gray; margin-left: -30px">
                {{ workout.wattsPerKilogram.toFixed(2) }} W/KG </small
              >&nbsp;&nbsp;
            </h6>
          </v-row>
          <v-row v-if="workout.workoutNotes" dense>
            <h4 class="font-weight-medium">
              {{ workout.workoutNotes }}
            </h4>
            &nbsp;&nbsp;
          </v-row>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import formulas from "../commons/formulas";

export default {
  name: "ThumbnailWorkoutComponent",
  props: {
    workout: Object,
  },
  mounted() {
    //console.log("Workout instance created!");
  },
  computed: {
    startDate: function () {
      if (!this.workout) return null;
      else {
        let today = new Date(this.workout.startDateTime);
        let date = `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`;
        return date;
      }
    },
    startTime: function () {
      if (!this.workout) return null;
      else {
        let today = new Date(this.workout.startDateTime);
        let time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
        return time;
      }
    },
    heartRateZone: function () {
      return formulas.getHeartRateZone(this.workout.heartRatePercentage);
    },
    heartRateZonePercentage: function () {
      return formulas.getHeartRateZonePercentage(
        this.workout.heartRatePercentage
      );
    },
  },
  data: () => ({}),
  methods: {
    openMapMyFitnessWorkout: function (event) {
      // @click="$router.push({ path: `/user/${user.userId}` })"
      window.open(this.workout.url, "_blank");
    },
  },
};
</script>
