import axios from "axios";
import proxy from "../../commons/proxy";
import groupModel from "../../models/groupModel.js";
import workoutModel from "../../models/workoutModel.js";
import challengeModel from "../../models/challengeModel.js";
import groupUserAssignModel from "../../models/groupUserAssignModel.js";

export default {
  namespaced: true,

  // Initial state
  state: {
    groups: null,
    group: null,
    members: null,
    challenges: null,
    activities: null,
  },

  // Getters
  getters: {
    getGroups: (state, getters, rootState) => {
      return state.groups;
    },
    getGroup: (state, getters, rootState) => {
      return state.group;
    },
    getActiveChallenges: (state, getters, rootState) => {
      if (state.challenges == null) return null;
      var todayDate = new Date().getTime();
      return state.challenges.filter(
        (challenge) =>
          todayDate >= new Date(challenge.startDate).getTime() &&
          todayDate <= new Date(challenge.finishDate).getTime()
      );
    },
    getExpiredChallenges: (state, getters, rootState) => {
      if (state.challenges == null) return null;
      var todayDate = new Date().getTime();
      return state.challenges.filter(
        (challenge) => todayDate >= new Date(challenge.finishDate).getTime()
      );
    },
    getByYearChallenges: (state) => (year) => {
      // If no challenges, return null
      if (!state.challenges) return null;
      // Get today's timestamp
      const todayDate = Date.now();
      return state.challenges.filter((challenge) => {
        const isExpired = todayDate >= new Date(challenge.finishDate).getTime();
        // Extract the year from challenge.startDate
        const challengeYear = new Date(challenge.startDate).getFullYear();
        // Return only challenges that are expired AND match the passed-in year
        return isExpired && challengeYear === year;
      });
    },
  },

  // Mutations
  mutations: {
    SET_Group(state, item) {
      if (item) {
        let groupObject = new groupModel(item);
        state.group = groupObject;
      } else {
        console.log("Failed to deserialize group!");
      }
    },
    SET_Groups(state, items) {
      if (items) {
        let deserializedCollection = [];
        for (let index = 0; index < items.length; ++index) {
          let groupJsonObject = items[index];
          let groupObject = new groupModel(groupJsonObject);
          deserializedCollection.push(groupObject);
        }
        state.groups = deserializedCollection;
      } else {
        console.log("Failed to deserialize group!");
      }
    },
    SET_Members(state, items) {
      if (items) {
        let deserializedCollection = [];
        for (let index = 0; index < items.length; ++index) {
          let groupJsonObject = items[index];
          let dataObject = new groupUserAssignModel(groupJsonObject);
          deserializedCollection.push(dataObject);
        }
        state.members = deserializedCollection;
        console.log(state.members);
      } else {
        console.log("Failed to deserialize group!");
      }
    },
    SET_Challenges(state, items) {
      if (items) {
        let deserializedCollection = [];
        for (let index = 0; index < items.length; ++index) {
          let groupChallengeAssignJsonObject = items[index];
          let dataObject = new challengeModel(groupChallengeAssignJsonObject);
          deserializedCollection.push(dataObject);
        }
        state.challenges = deserializedCollection;
      } else {
        console.log("Failed to deserialize group challenges!");
      }
    },
    SET_Activities(state, items) {
      if (items) {
        let deserializedCollection = [];
        for (let index = 0; index < items.length; ++index) {
          let groupJsonObject = items[index];
          let dataObject = new workoutModel(groupJsonObject);
          deserializedCollection.push(dataObject);
        }
        state.activities = deserializedCollection;
      } else {
        console.log("Failed to deserialize group activities!");
      }
    },
    RESET(state) {
      state.group = null;
      state.groups = null;
      state.members = null;
      state.challenges = null;
      state.activities = null;
    },
  },

  // Action
  actions: {
    getGroup({ commit, state }, primaryKey) {
      axios
        .get(`${proxy.baseUrl}/group/get/${primaryKey}`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Group", response.data);
        });
    },
    getGroups({ commit, state }, data) {
      axios
        .get(`${proxy.baseUrl}/group/get`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Groups", response.data);
        });
    },
    getGroupMembers({ commit, state }, primaryKey) {
      axios
        .get(`${proxy.baseUrl}/groupUser/getByGroup/${primaryKey}`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Members", response.data);
        });
    },
    getGroupActivities({ commit, state }, { primaryKey, skip }) {
      axios
        .get(
          `${proxy.baseUrl}/groupUser/getActivities?groupId=${primaryKey}&skip=${skip}&take=50`,
          {
            headers: { Authorization: `Beaer ${proxy.getToken()}` },
          }
        )
        .then((response) => {
          commit("SET_Activities", response.data);
        });
    },
    getGroupChallenges({ commit, state }, primaryKey) {
      axios
        .get(`${proxy.baseUrl}/groupChallenge/getByGroup/${primaryKey}`, {
          headers: { Authorization: `Beaer ${proxy.getToken()}` },
        })
        .then((response) => {
          commit("SET_Challenges", response.data);
        });
    },
  },
};
